import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../../i18n';

const CustomLink = props => {
  const { href, asPath, prefetch, className, children, ...rest } = props;

  return (
    <Link href={href || '/'} as={asPath} prefetch={prefetch} scroll={false}>
      <a className={className} {...rest}>
        {children}
      </a>
    </Link>
  );
};

CustomLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  asPath: PropTypes.string,
  prefetch: PropTypes.bool
};

CustomLink.defaultProps = {
  children: null,
  className: '',
  asPath: undefined,
  prefetch: false
};

export default CustomLink;
