/* eslint-disable import/no-unresolved */
import React from 'react';
import App from 'next/app';
import { ThemeProvider } from 'styled-components';

import getConfig from 'next/config';

import Router from 'next/router';
import TagManager from 'react-gtm-module';

import { DataProvider, RouterProvider, ScrollProvider } from '@ccg/core';

// Import based on ACTIVE_SITE env. Resolvers defined in next.config.js
import { tokens, GlobalStyle, getColorSet } from '@components';

import { startProgressBar, stopProgressBar } from '../client/progressBar';
import { appWithTranslation, i18n } from '../i18n';

import Link from '../components/shared/Link/Link';
import LoginProvider from '../contexts/LoginProvider';
import PageTransition from '../components/PageTransition/PageTransition';

import {
  getContentType,
  getCiColors,
  getColorTheme,
  getBackground,
  updateModularTokens
} from '../helper';

// public runtime config
const {
  publicRuntimeConfig: { ACTIVE_SITE, GOOGLE_TAGMANAGER_ID, GOOGLE_TAGMANAGER_ID_EN }
} = getConfig();

// Progress Bar
Router.events.on('routeChangeStart', () => startProgressBar());
Router.events.on('routeChangeComplete', () => stopProgressBar());
Router.events.on('routeChangeError', () => stopProgressBar());

class CCGApp extends App {
  componentDidMount() {
    if (ACTIVE_SITE !== 'fork' && ACTIVE_SITE !== 'fischerappelt') {
      TagManager.initialize({
        gtmId:
          i18n.language === 'en' && GOOGLE_TAGMANAGER_ID_EN
            ? GOOGLE_TAGMANAGER_ID_EN
            : GOOGLE_TAGMANAGER_ID
      });
    }
  }

  static async getInitialProps(appCtx) {
    const initialProps = await App.getInitialProps(appCtx);

    const safeHostName =
      typeof window !== 'undefined'
        ? window.location.host
        : appCtx.ctx.req.headers.host.replace(/(<([^>]+)>)/gi, '');
    const i18nLang = i18n.language;
    const { localeSubpaths } = i18n.options;

    return {
      siteUrl:
        i18nLang && localeSubpaths && localeSubpaths[i18nLang]
          ? `https://${safeHostName}/${i18nLang}`
          : `https://${safeHostName}`,
      siteDomain: `https://${safeHostName}`,
      ...initialProps
    };
  }

  render() {
    const { Component, pageProps, router, siteUrl, siteDomain } = this.props;

    const contentType = getContentType(pageProps);
    const clientCi = getCiColors(pageProps);
    const colorTheme = getColorTheme(pageProps);
    const background = getBackground(pageProps);
    const colorSets = getColorSet(
      tokens.colors,
      tokens.colorSets,
      clientCi,
      colorTheme,
      background
    );

    const theme = {
      ...updateModularTokens(tokens, contentType),
      contentType,
      colorTheme,
      background,
      colorSets,
      clientCi
    };

    return (
      <ThemeProvider theme={theme}>
        <DataProvider data={{ contentType, siteUrl, siteDomain, ...pageProps }}>
          <RouterProvider router={{ link: Link }}>
            <ScrollProvider>
              <LoginProvider>
                <GlobalStyle />
                <PageTransition timeout={500} classNames="transition" skipInitialTransition>
                  <Component
                    {...pageProps}
                    key={
                      typeof window !== 'undefined'
                        ? window.location.pathname
                        : router.query.uri || router.asPath
                    }
                  />
                </PageTransition>
              </LoginProvider>
            </ScrollProvider>
          </RouterProvider>
        </DataProvider>
      </ThemeProvider>
    );
  }
}

export default appWithTranslation(CCGApp);
