/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import { differentChildrenNeedAnimation, areChildrenDifferent, buildClassName } from './helper';

class PageTransition extends React.Component {
  constructor(props) {
    super(props);

    const { children } = props;

    this.state = {
      state: props.skipInitialTransition ? 'init' : 'enter',
      isIn: true,
      currentChildren: children,
      nextChildren: null,
      renderedChildren: children
    };
  }

  componentDidUpdate() {
    const { currentChildren, renderedChildren, nextChildren, isIn, state } = this.state;

    const { children } = this.props;

    const hasNewChildren = areChildrenDifferent(currentChildren, children);
    const needsTransition = areChildrenDifferent(renderedChildren, children);

    const shouldAnimateTransition =
      needsTransition && differentChildrenNeedAnimation(renderedChildren, children);

    if (isIn && needsTransition && !shouldAnimateTransition) {
      // We need to update our rendered children, but we shouldn't animate them.
      // This will occur when the key prop on our children stays the same but
      // the children themselves change. This can happen in a lot of cases: HMR,
      // a rerender due to a Redux state change, a Router.push to the current page,
      // etc. In this case, we'll just immediately flush the children to be
      // rendered.
      this.setState({
        currentChildren: children,
        renderedChildren: children
      });
    } else if (hasNewChildren) {
      // We got a new set of children while we were transitioning some in
      // Immediately start transitioning out this component and update the next
      // component
      this.setState({
        isIn: false,
        nextChildren: children,
        currentChildren: children
      });
    } else if (needsTransition && !isIn && (state === 'enter' || state === 'exited')) {
      // No need to wait, mount immediately
      this.setState({
        isIn: true,
        renderedChildren: nextChildren,
        nextChildren: null
      });
    }
  }

  onChildLoaded() {
    // We can immediately bring in the next page!
    this.setState({
      isIn: true
    });
  }

  updateState(state, otherProps) {
    this.setState({
      state,
      ...otherProps
    });
  }

  render() {
    const { timeout, classNames, skipInitialTransition } = this.props;
    const { renderedChildren: children, state, isIn } = this.state;

    if (['entering', 'exiting', 'exited'].indexOf(state) !== -1) {
      // eslint-disable-next-line no-unused-expressions
      if (document.body) document.body.scrollTop;
    }

    const containerClassName = buildClassName(classNames, state);

    return (
      <>
        <Transition
          timeout={timeout}
          in={isIn}
          appear={!skipInitialTransition}
          onEnter={() => this.updateState('enter')}
          onEntering={() => this.updateState('entering')}
          onEntered={() => this.updateState('entered')}
          onExit={() => this.updateState('exit')}
          onExiting={() => this.updateState('exiting')}
          onExited={() => {
            if (typeof window !== 'undefined') {
              window.scrollTo(0, 0);
            }
            this.updateState('exited', { renderedChildren: null });
          }}
        >
          <div className={containerClassName}>
            {children &&
              React.cloneElement(children, {
                pageTransitionReadyToEnter: () => this.onChildLoaded()
              })}
          </div>
        </Transition>
      </>
    );
  }
}

PageTransition.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  skipInitialTransition: PropTypes.bool
};

PageTransition.defaultProps = {
  timeout: 0,
  skipInitialTransition: false
};

export default PageTransition;
