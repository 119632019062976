import React from 'react';

export const areChildrenDifferent = (oldChildren, newChildren) => {
  return oldChildren !== newChildren;
};

export const differentChildrenNeedAnimation = (oldChildren, newChildren) => {
  if (!oldChildren || !newChildren) {
    return true;
  }

  if (!React.isValidElement(oldChildren) || !React.isValidElement(newChildren)) {
    // eslint-disable-next-line no-console
    console.warn('[next-page-transitions] PageTransition child is not a valid React component');
    return true;
  }

  if (oldChildren.key == null || newChildren.key == null) {
    // eslint-disable-next-line no-console
    console.warn('[next-page-transitions] PageTransition child does not have a key');
    return true;
  }

  return oldChildren.key !== newChildren.key;
};

export const buildClassName = (className, state) => {
  switch (state) {
    case 'enter':
      return `${className}-enter`;
    case 'entering':
      return `${className}-enter ${className}-enter-active`;
    case 'entered':
      return `${className}-enter-done`;
    case 'exit':
      return `${className}-exit`;
    case 'exiting':
      return `${className}-exit ${className}-exit-active`;
    case 'exited':
      return `${className}-exit-done`;
    default:
      return '';
  }
};
